import { makeStyles } from "@material-ui/core/styles";

export const useEditStyles = makeStyles((theme) => ({
    topBar: {
        width: '100%',
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        [theme.breakpoints.down('lg')]: {
            height: 60,
        },
    },
}))
