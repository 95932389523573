import React, { Fragment, useEffect, useRef, useState } from 'react'
import { fabric } from 'fabric'
import { Button, Collapse, Divider, Grid, Stack, TextField, Typography } from '@mui/material'

import fabricCloneControl from '../fabricControls/clone'
import fabricDeleteControl from '../fabricControls/delete'
import fabricRotateControl from '../fabricControls/rotate'
import fabricScalingControl from '../fabricControls/scaling'
import { useNavigate, useSearchParams } from 'react-router-dom'
import EditText from '../components/EditText'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEditStyles } from '../components/styled/useStyles/EditPageStyles'
import { CloseButton, FullScreenDialog, MediaContainer, Root, TransparentInput } from '../components/styled/EditCanvasStyled'
import NewEditText from '../components/NewEditText'

const NewEditCanvas = () => {

  const [params, setParams] = useSearchParams()

  const navigate = useNavigate()

  const iTextRef = useRef(null)
  const canvasRef = useRef(null)

  const [font, setFont] = useState(0)
  const [text, setText] = useState('')
  const [list, setList] = useState([])
  const [showColors, setShowColors] = useState(false)
  const [image, setImage] = useState('')
  const [newText, setNewText] = useState('')
  const [color, setColor] = useState('black')
  const [fontStyle, setFontStyle] = useState(0)
  const [canvasInstance, setCanvasInstance] = useState(null)

  const createIText = () => {
    if (!newText) return

    const HideControls = {
      'tl': false,
      'tr': false,
      'bl': false,
      'br': true,
      'ml': false,
      'mt': false,
      'mr': false,
      'mb': false,
      'mtr': true
    }

    const iText = new fabric.IText(newText, {
      left: 150,
      top: 200,
      fontSize: 20,
      cornerStyle: 'circle',
      cornerColor: 'white',
      borderColor: 'black',
      cornerSize: 10,
      editingBorderColor: 'black',
      transparentCorners: false,
      padding: 6,
      textAlign: 'center'
    })

    iText.setControlsVisibility(HideControls)
    canvasInstance.add(iText)
    canvasInstance.renderAll()
    setNewText('')
  }

  function handleFileUpload(event) {
    const file = event.target.files[0]

    if (file) {
      const reader = new FileReader()

      reader.readAsDataURL(file)

      reader.onloadend = function (e) {
        setImage({
          src: e.target.result,
          height: 500,
          width: 500
        })
      }
    }
  }

  canvasInstance?.on('selection:created', function (e) {
    const style = e.selected[0].fontStyle
    const font = e.selected[0].fontFamily

    iTextRef.current = e.selected[0]
    setFont(font)
    setFontStyle(style)
    setText(e.selected[0].text)
    setColor(e.selected[0].fill)
  })

  canvasInstance?.on('selection:updated', (e) => {
    const style = e.selected[0].fontStyle
    const font = e.selected[0].fontFamily

    iTextRef.current = e.selected[0]
    setFont(font)
    setFontStyle(style)
    setText(e.selected[0].text)
    setColor(e.selected[0].fill)
    setShowColors(false)
  })

  canvasInstance?.on('selection:cleared', (e) => {
    iTextRef.current = null
    setText('')
    setColor('black')
    setShowColors(false)
  })

  // canvasInstance?.on('object:added', (e) => {
  //   setList([...list, e.target])
  //   e.target.set({
  //     borderColor: 'black',
  //     cornerSize: 20,
  //     transparentCorners: true
  //   })
  // })
  canvasInstance?.on('object:removed', (e) => {
    const newList = [...canvasInstance._objects]
    setList(newList)
  })

  // canvasInstance?.on('object:selected', function (e) {
  //   e.target.set({
  //     borderColor: 'black',
  //     cornerSize: 20,
  //     transparentCorners: false
  //   })
  // })

  const handleSelectEdit = (item) => {
    iTextRef.current = item
    canvasInstance.setActiveObject(item)
    canvasInstance.renderAll()
  }

  const handleDeleteText = (item) => {
    const newArray = list.filter((t) => t !== item)
    setList(newArray)
    canvasInstance.remove(item)
  }

  const handleEditText = () => {
    const newList = [...list]
    const checkIndex = newList.indexOf(iTextRef.current)
    iTextRef.current.set("text", text)
    canvasInstance.renderAll()
    newList.splice(checkIndex, 1, iTextRef.current)
    setList(newList)
    setText('')
  }

  const handleDaownloadImage = (event) => {
    console.log('entro');
    canvasInstance.discardActiveObject()
    canvasInstance.renderAll()
    let link = event.currentTarget
    link.setAttribute('download', 'myImage.png')
    let image = canvasRef.current.toDataURL('image/png')
    link.setAttribute('href', image)
  }

  const handleColorChange = (color) => {
    setColor(color)
    iTextRef.current.set('fill', color)
    canvasInstance.renderAll()
  }

  const handleFontChange = (value) => {
    iTextRef.current.set('fontFamily', value)
    canvasInstance.renderAll()
  }

  const handleFontSyle = (value) => {
    iTextRef.current.set('fontStyle', value)
    canvasInstance.renderAll()
  }

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
  }

  const handleShare = () => {
    canvasInstance.discardActiveObject()
    canvasInstance.renderAll()
    let image = canvasRef.current.toDataURL()
    const blob = dataURItoBlob(image);
    const file = new File([blob], "image.png", { type: "image/png" });

    window.FB.ui({
      method: 'share',
      href: 'https://s3-us-west-1.amazonaws.com/calzzapato/zoom/09FYAB-1.jpg', // URL del enlace a compartir
      quote: '¡Mira esta imagen!', // Texto que aparecerá en la publicación compartida
      picture: 'https://s3-us-west-1.amazonaws.com/calzzapato/zoom/09FYAB-1.jpg', // URL de la imagen a compartir
    }, (response) => {
      console.log(response)
    })

  }

  const handleBackPage = () => navigate(-1)

  const containerRef = useRef(null)

  useEffect(() => {

    if (image) {
      const container = containerRef.current
      const canvas = new fabric.Canvas(canvasRef.current)
      setCanvasInstance(canvas)
      canvas.setWidth(container.clientWidth)
      canvas.setHeight(container.clientWidth)
      canvas.selection = false

      fabric.Image.fromURL(image, (fabricImage) => {
        const check = fabricImage.width - fabricImage.height
        if (fabricImage.width > fabricImage.height && check > 500) {
          fabricImage.scaleToWidth(canvas.width)
        } else {
          fabricImage.scaleToHeight(canvas.height)
        }
        canvas.setBackgroundImage(fabricImage, canvas.renderAll.bind(canvas))
      })

      canvas.renderAll()

    }

    fabricScalingControl(fabric)
    fabricRotateControl(fabric)
  }, [image])

  useEffect(() => {
    const checkParam = params.get('imagen')
    const imageUrl = checkParam == 1 ? '/kelder.png' : checkParam == 2 ? '/estrella.jpeg' : checkParam == 3 ? '/mama-quiere.jpeg' : 'new-image.png'
    setImage(imageUrl)
  }, [image])

  return (
    <div style={{ height: '100vh', width: '100%', backgroundColor: '#17202A' }}>
      <Root>
        <CloseButton onClick={handleBackPage} />
        {/* <Collapse in={iTextRef.current}>
          <NewEditText
            color={color}
            setColor={setColor}
            showColors={showColors}
            setShowColors={setShowColors}
            handleColorChange={handleColorChange}
          />
        </Collapse> */}
        <MediaContainer ref={containerRef}>
          <canvas ref={canvasRef} />
          {
            iTextRef.current
              ?
              <NewEditText
                color={color}
                setColor={setColor}
                showColors={showColors}
                setShowColors={setShowColors}
                handleColorChange={handleColorChange}
              />
              :
              <TransparentInput
                value={newText}
                onChange={({ target: { value } }) => setNewText(value)}
                onClick={createIText}
                placeholder='Agregar texto' />
          }
        </MediaContainer>
      </Root>
    </div>
  )
}

export default NewEditCanvas