import React, { Fragment, useEffect, useRef, useState } from 'react'
import { fabric } from 'fabric'
import { Button, Collapse, Divider, Grid, Stack, TextField, Typography } from '@mui/material'

import fabricCloneControl from '../fabricControls/clone'
import fabricDeleteControl from '../fabricControls/delete'
import fabricRotateControl from '../fabricControls/rotate'
import fabricScalingControl from '../fabricControls/scaling'
import { useNavigate, useSearchParams } from 'react-router-dom'
import EditText from '../components/EditText'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEditStyles } from '../components/styled/useStyles/EditPageStyles'

const EditCanvas = () => {

  const classes = useEditStyles();

  const [params, setParams] = useSearchParams()

  const navigate = useNavigate()

  const iTextRef = useRef(null)
  const canvasRef = useRef(null)

  const [font, setFont] = useState(0)
  const [text, setText] = useState('')
  const [list, setList] = useState([])
  const [share, setShare] = useState('')
  const [image, setImage] = useState('')
  const [newText, setNewText] = useState('')
  const [color, setColor] = useState('black')
  const [fontStyle, setFontStyle] = useState(0)
  const [canvasInstance, setCanvasInstance] = useState(null)

  const createIText = () => {
    const HideControls = {
      'tl': false,
      'tr': false,
      'bl': false,
      'br': true,
      'ml': false,
      'mt': false,
      'mr': false,
      'mb': false,
      'mtr': true
    }

    const iText = new fabric.IText(newText, {
      fontSize: 20,
      left: 100,
      top: 100,
      fontStyle: 'normal',
      textAlign: 'center',
      editable: true,
      centeredScaling: true,
      centeredRotation: true,
      fill: 'white'
    })

    iText.setControlsVisibility(HideControls)
    iTextRef.current = iText
    canvasInstance.add(iText)
    canvasInstance.setActiveObject(iText)
    canvasInstance.renderAll()
    setNewText('')
  }

  function handleFileUpload(event) {
    const file = event.target.files[0]

    if (file) {
      const reader = new FileReader()

      reader.readAsDataURL(file)

      reader.onloadend = function (e) {
        setImage({
          src: e.target.result,
          height: 500,
          width: 500
        })
      }
    }
  }

  canvasInstance?.on('selection:created', function (e) {
    const style = e.selected[0].fontStyle
    const font = e.selected[0].fontFamily

    iTextRef.current = e.selected[0]
    setFont(font)
    setFontStyle(style)
    setText(e.selected[0].text)
    setColor(e.selected[0].fill)
  })

  canvasInstance?.on('selection:updated', (e) => {
    const style = e.selected[0].fontStyle
    const font = e.selected[0].fontFamily

    iTextRef.current = e.selected[0]
    setFont(font)
    setFontStyle(style)
    setText(e.selected[0].text)
    setColor(e.selected[0].fill)
  })

  canvasInstance?.on('selection:cleared', (e) => {
    iTextRef.current = null
    setText('')
    setColor('black')
  })

  canvasInstance?.on('object:added', (e) => {
    setList([...list, e.target])
    e.target.set({
      borderColor: 'black',
      cornerSize: 20,
      transparentCorners: true
    })
  })
  canvasInstance?.on('object:removed', (e) => {
    const newList = [...canvasInstance._objects]
    setList(newList)
  })

  canvasInstance?.on('object:selected', function (e) {
    e.target.set({
      borderColor: 'black',
      cornerSize: 20,
      transparentCorners: true
    })
  })

  const handleSelectEdit = (item) => {
    iTextRef.current = item
    canvasInstance.setActiveObject(item)
    canvasInstance.renderAll()
  }

  const handleDeleteText = (item) => {
    const newArray = list.filter((t) => t !== item)
    setList(newArray)
    canvasInstance.remove(item)
  }

  const handleEditText = () => {
    const newList = [...list]
    const checkIndex = newList.indexOf(iTextRef.current)
    iTextRef.current.set("text", text)
    canvasInstance.renderAll()
    newList.splice(checkIndex, 1, iTextRef.current)
    setList(newList)
    setText('')
  }

  const handleDaownloadImage = (event) => {
    console.log('entro');
    canvasInstance.discardActiveObject()
    canvasInstance.renderAll()
    let link = event.currentTarget
    link.setAttribute('download', 'myImage.png')
    let image = canvasRef.current.toDataURL('image/png')
    link.setAttribute('href', image)
  }

  const handleColorChange = (color) => {
    setColor(color)
    iTextRef.current.set('fill', color)
    canvasInstance.renderAll()
  }

  const handleFontChange = (value) => {
    iTextRef.current.set('fontFamily', value)
    canvasInstance.renderAll()
  }

  const handleFontSyle = (value) => {
    iTextRef.current.set('fontStyle', value)
    canvasInstance.renderAll()
  }

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/png' });
  }

  const handleShare = () => {
    canvasInstance.discardActiveObject()
    canvasInstance.renderAll()
    let image = canvasRef.current.toDataURL()
    const blob = dataURItoBlob(image);
    const file = new File([blob], "image.png", { type: "image/png" });

    window.FB.ui({
      method: 'share',
      href: 'https://s3-us-west-1.amazonaws.com/calzzapato/zoom/09FYAB-1.jpg', // URL del enlace a compartir
      quote: '¡Mira esta imagen!', // Texto que aparecerá en la publicación compartida
      picture: 'https://s3-us-west-1.amazonaws.com/calzzapato/zoom/09FYAB-1.jpg', // URL de la imagen a compartir
    }, (response) => {
      console.log(response)
    })

  }

  const handleBackPage = () => navigate(-1)

  useEffect(() => {
    if (image) {
      const width = window.innerWidth
      const canvas = new fabric.Canvas(canvasRef.current)
      setCanvasInstance(canvas)
      canvas.setWidth((width > 800) ? 600 : width - 20)
      canvas.setHeight((width > 800) ? 600 : width - 20)
      canvas.selection = false

      fabric.Image.fromURL(image, (fabricImage) => {
        const check = fabricImage.width - fabricImage.height
        if (fabricImage.width > fabricImage.height && check > 500) {
          fabricImage.scaleToWidth(canvas.width)
        } else {
          fabricImage.scaleToHeight(canvas.height)
        }
        canvas.setBackgroundImage(fabricImage, canvas.renderAll.bind(canvas))
      })
      canvas.renderAll()

    }
    fabricCloneControl(fabric)
    fabricDeleteControl(fabric)
    fabricScalingControl(fabric)
    fabricRotateControl(fabric)
  }, [image])

  useEffect(() => {
    const checkParam = params.get('imagen')
    const imageUrl = checkParam == 1 ? '/kelder.png' : checkParam == 2 ? '/estrella.jpeg' : checkParam == 3 ? '/mama-quiere.jpeg' : 'new-image.png'
    setImage(imageUrl)
  }, [image])

  return (
    <div style={{ paddingBottom: 32 }}>
      <div className={classes.topBar}>
        <Button
          onClick={handleBackPage}
          sx={{ textTransform: 'none', maxHeight: 50, marginLeft: 2 }}
          startIcon={<ArrowBackIosIcon />}
        >Regresar</Button>
      </div>
      <Divider variant='middle' />
      <Grid container spacing={3} sx={{ paddingTop: 1 }} justifyContent='center'  >
        <Grid item sx={6}>
          <canvas ref={canvasRef} />
        </Grid>
        <Grid item xs={12} md={3}  >
          <Collapse in={!iTextRef.current}>
            <Stack spacing={2}>
              {
                !iTextRef.current && (
                  <Stack spacing={2} direction={'column'} sx={{ marginLeft: 1 }}>
                    <Stack direction='row' spacing={1}>
                      <TextField
                        autoComplete='off'
                        size='small'
                        multiline={true}
                        placeholder='Crea un texto'
                        inputProps={{ maxLength: 50 }}
                        maxRows={3}
                        value={newText}
                        onChange={({ target: { value } }) => setNewText(value)}
                      />
                      <Button
                        disabled={!newText.trim() || list.length === 3}
                        variant='outlined'
                        sx={{
                          maxHeight: 38,
                          textTransform: 'none'
                        }}
                        onClick={createIText}
                      >Agregar</Button>
                    </Stack>
                    {
                      list.map((item, index) => (
                        <Fragment key={index}>
                          <Stack direction='row' textAlign='center' alignItems='center' spacing={2}>
                            <Stack direction='row'>
                              <Typography
                                style={{
                                  width: 150,
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  whiteSpace: 'nowrap',
                                  backgroundColor: '#f2f4f5',
                                  borderRadius: 3,
                                }}
                              >{item.text}</Typography>
                            </Stack>
                            <Button
                              sx={{ textTransform: 'none' }}
                              size='small'
                              variant='outlined'
                              color='error'
                              onClick={() => handleDeleteText(item)}
                            >Eliminar</Button>
                            <Button
                              sx={{ textTransform: 'none' }}
                              size='small'
                              variant='outlined'
                              onClick={() => handleSelectEdit(item)}
                            >Editar</Button>
                          </Stack>
                        </Fragment>
                      ))
                    }
                  </Stack>
                )
              }
            </Stack>
          </Collapse>
          <Collapse in={iTextRef.current} >
            {
              iTextRef.current && (
                <EditText
                  font={font}
                  text={text}
                  setText={setText}
                  setFont={setFont}
                  handleFontChange={handleFontChange}
                  fontStyle={fontStyle}
                  setFontStyle={setFontStyle}
                  handleFontSyle={handleFontSyle}
                  handleEditText={handleEditText}
                  color={color}
                  setColor={setColor}
                  handleColorChange={handleColorChange}
                />
              )
            }
          </Collapse>
        </Grid>
      </Grid>
      <a onClick={handleDaownloadImage}>Descargar</a>
    </div>
  )
}

export default EditCanvas