import React from 'react'

// MUI
import { styled } from '@mui/system';
import { Dialog, IconButton, InputAdornment, InputBase, ButtonBase } from '@mui/material'
import { Close, Add } from '@mui/icons-material';

export const FullScreenDialog = styled((props) => <Dialog fullScreen {...props} />)({
    '& > .MuiDialog-container > .MuiPaper-root': {
        backgroundColor: '#17202A',
    }
})

export const Root = styled('div')({
    position: 'relative',
    display: 'block',
    width: '100%',
    height: '100%',
});

export const MediaContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 'calc(100% - 32px)',
    width: '512px',
    height: 'calc(100% - 128px)',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    borderRadius: '6px',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
    overflow: 'hidden',
});

export const Media = styled('img')({
    width: '100%',
    objectFit: 'contain'
})

export const CloseIcon = styled((props) => <Close {...props} />)({
    color: 'white'
});

export const CloseButton = styled((props) => <IconButton {...props}> <CloseIcon /> </IconButton>)({
    position: 'absolute',
    right: '12px',
    top: '12px'
})

export const TransparentInput = styled(({ onClick, ...props }) => <InputBase {...props} endAdornment={<InputAdornment position='end'><AddButton onClick={onClick} /></InputAdornment>} />)({
    position: 'absolute',
    bottom: '16px',
    left: '50%',
    transform: 'translateX(-50%)',
    width: 'calc(100% - 32px)',
    height: '48px',
    padding: '0px 24px',
    border: '1px solid rgba(255, 255, 255, 0.6)',
    borderRadius: '48px',
    color: 'rgba(255, 255, 255, 0.6)'
});

export const AddButton = styled((props) => <ButtonBase {...props}>Agregar</ButtonBase>)({
    width: '128px',
    height: '32px',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '32px',
    color: 'rgba(255, 255, 255, 0.6)'
})

export const AddIcon = styled((props) => <Add {...props} />)({
    color: 'rgba(255, 255, 255, 0.6)'
});
