import React from 'react'

const Palette = ({ colors, onSelectColor }) => {

  return (
    <div >
      {colors.map(color => (
        <div
          key={color}
          style={{ border: 'solid 1px grey', borderRadius: 20, marginLeft: 12, backgroundColor: color, width: '10px', height: '10px', display: 'inline-block', cursor: 'pointer' }}
          onClick={() => onSelectColor(color)}
        />
      ))}
    </div>
  );
}


export default Palette