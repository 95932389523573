import { Button, Collapse, FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import React, { useState } from 'react'
import PaletteIcon from '@mui/icons-material/Palette';
import Palette from './palette';

const EditText = (props) => {

  const [editColor, setEditColor] = useState(false)

  const colors = ['#000000', '#ffffff', '#ff0000', '#00ff00', '#0000ff', '#ffff00', '#00ffff', '#ff00ff']

  const fontsFamily = [{
    id: 1,
    name: 'Times New Roman'
  },
  {
    id: 2,
    name: "Monaco"
  },
  {
    id: 3,
    name: 'Verdana'
  },
  {
    id: 4,
    name: "Arial narrow"
  },

  ]

  const fontsStyles = [{
    id: 1,
    name: "normal"
  }, {
    id: 2,
    name: "italic"
  }, {
    id: 3,
    name: 'oblique'
  }]

  const toggelEditColor = () => setEditColor(!editColor)

  function handleSelectColor(color) {
    props.handleColorChange(color)
  }

  const checkIconColor = props.color === 'white' || props.color === '#ffffff' ? 'black' : ''
  return (
    <>
      <Stack spacing={2} sx={{ marginLeft: 1 }}>
        <Stack direction={'row'} spacing={1}>
          <TextField
            size='small'
            placeholder='Edita el texto'
            multiline={true}
            inputProps={{ maxLength: 50 }}
            maxRows={3}
            value={props.text}
            onChange={({ target: { value } }) => props.setText(value)}
          />
          <Button
            sx={{
              maxHeight: 38,
              textTransform: 'none'
            }}

            disabled={!props.text}
            onClick={props.handleEditText}
            size='small'
            variant='outlined'>Cambiar</Button>
        </Stack>
        <Stack direction='row' spacing={1}>

          <FormControl
            sx={{ maxWidth: 200, minWidth: 200 }}
          >
            <InputLabel id="fontFamily">Fuente</InputLabel>
            <Select
              size='small'
              id="fontFamily"
              value={props.font}
              label="fontFamily"
              onChange={({ target: { value } }) => {
                props.setFont(value)
                props.handleFontChange(value)
              }}
            >
              <MenuItem disabled value={0}>Selecciona una fuente</MenuItem>
              {
                fontsFamily.map((item) => (
                  <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          <IconButton
            sx={{ maxHeight: 37 }}
            onClick={toggelEditColor}

          >
            <PaletteIcon
              sx={{ color: props.color, backgroundColor: checkIconColor, borderRadius: 2 }} />
          </IconButton>
          <Collapse in={editColor} orientation='vertical'>
            <Palette colors={colors} onSelectColor={handleSelectColor} />
          </Collapse>
        </Stack>
        <FormControl
          sx={{ maxWidth: 200, minWidth: 200 }}
        >
          <InputLabel id="font">Estilo</InputLabel>
          <Select
            size='small'
            id="fontSyle"
            value={props.fontStyle}
            label="Estilo"
            onChange={({ target: { value } }) => {
              props.setFontStyle(value)
              props.handleFontSyle(value)
            }}
          >
            <MenuItem disabled key={0} value={0}>Selecciona un estilo</MenuItem>
            {
              fontsStyles.map(item => (
                <MenuItem key={item.id} value={item.name}>{item.name}</MenuItem>
              ))
            }
          </Select>
        </FormControl>


      </Stack>
    </>
  )
}

export default EditText