import { Collapse, IconButton } from '@mui/material'
import React, { useState } from 'react'
import Palette from './palette'
import PaletteIcon from '@mui/icons-material/Palette';

const NewEditText = (props) => {

  const [editColor, setEditColor] = useState(false)

  const colors = ['#000000', '#ffffff', '#ff0000', '#00ff00', '#0000ff', '#ffff00', '#00ffff', '#ff00ff']

  const toggelEditColor = () => props.setShowColors(!props.showColors)

  function handleSelectColor(color) {
    props.handleColorChange(color)
  }

  const checkIconColor = props.color === 'white' || props.color === '#ffffff' ? 'black' : ''
  return (
    <div style={{ zIndex: 2, position: 'absolute', bottom: 20, left: 20 }}>
      <Collapse in={props.showColors} orientation='vertical'>
        <Palette colors={colors} onSelectColor={handleSelectColor} />
      </Collapse>
      <IconButton
        sx={{ maxHeight: 37 }}
        onClick={toggelEditColor}
      >
        <PaletteIcon
          sx={{ color: 'white', backgroundColor: checkIconColor, borderRadius: 2 }} />
      </IconButton>
    </div>
  )
}

export default NewEditText