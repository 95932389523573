import { Route, Routes } from 'react-router-dom';
import EditCanvas from './pages/EditCanvas';
import Home from './pages/Home';
import NewEditCanvas from './pages/NewEditCanvas';

function App() {

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/editar' element={<EditCanvas />} />
      <Route path='/new-editar' element={<NewEditCanvas />} />
    </Routes>
  );
}

export default App;
