import { makeStyles } from '@material-ui/core';

export const useHomeStyles = makeStyles((theme) => ({
  button: {
    height: 250,
    width: 'calc(100%)',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('600')]: {
      height: 200,
    },
    '&:hover $image': {
      transform: 'scale(1.2)',
    },
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: 'transform 0.2s ease-in-out',
  }
}))