import { Button, Grid, Hidden, Stack } from '@mui/material';
import { BannerBlock, Content, DoListImg } from '../components/styled';
import { Fragment } from 'react';
import { useHomeStyles } from '../components/styled/useStyles/HomeStyles';

const Home = () => {

  const classes = useHomeStyles()

  const images = [
    {
      id: 1,
      url: '/09ELKS.jpg',
      code: '09ELKS'
    },
    {
      id: 2,
      url: '/09GQT7.jpg',
      code: '09GQT7'
    },
    {
      id: 3,
      url: '/09GTQ9.jpg',
      code: '09GTQ9'
    },
    {
      id: 4,
      url: '/09GVHT.jpg',
      code: '09GVHT'
    },
  ]
  const images2 = [
    {
      id: 1,
      url: '/09H0H9.jpg',
      code: '09H0H9'
    },
    {
      id: 2,
      url: '/09H3PS.jpg',
      code: '09H3PS'
    },
    {
      id: 3,
      url: '/09H19D.jpg',
      code: '09H19D'
    },
    {
      id: 4,
      url: '/09H34C.jpg',
      code: '09H34C'
    },
  ]

  const handleRouter = (code) => window.open(`https://www.kelder.mx/${code}`)

  return (
    <Content>
      <Stack spacing={2} >
        <Hidden mdDown>
          <div style={{ display: 'block', height: '350px', borderRadius: 5 }}>
            <img height='100%' width='100%' src='/mama-dijo.jpg' alt='' style={{ borderRadius: 5 }} />
          </div>
        </Hidden>
        <Hidden mdUp>
          <div style={{ display: 'block', width: '100vw' }}>
            <img width='100%' src='/mama-dijo-cuadro.jpg' alt='' />
          </div>
        </Hidden>
        <BannerBlock>
          <Grid container alignItems='center' spacing={2}>
            <Grid item xs={12} md={6} >
              <img height='100%' width='100%' src='/600X500.webp' alt='' style={{ borderRadius: 5 }} />
            </Grid>
            <Grid item xs={12} md={6} >
              <a href="https://kelder.mx/mi-cuenta/wishlist?mkt=true">
                <DoListImg src='/hacer-lista.jpg' alt='' />
              </a>
            </Grid>
          </Grid>
        </BannerBlock>
        <div style={{ display: 'block' }}>
          <Grid container spacing={{ xs: 1, md: 5 }} >
            {
              images.map((image, index) => (
                <Fragment key={index}>
                  <Grid item xs={6} md={3} style={{}}>
                    <Button
                      onClick={() => handleRouter(image.code)}
                      className={classes.button}>
                      <img className={classes.image} src={image.url} alt="" />
                    </Button>
                  </Grid>
                </Fragment>
              ))
            }
          </Grid>
        </div>
        <Hidden mdDown>
          <div style={{ display: 'block', height: '350px', borderRadius: 5 }}>
            <img height={'100%'} width='100%' src='/comparte.jpg' alt='' style={{ borderRadius: 5 }} />
          </div>
        </Hidden>
        <Hidden mdUp>
          <div style={{ display: 'block', width: '100vw', borderRadius: 5 }}>
            <img width='100%' src='/comparte-cuadro.jpg' alt='' style={{ borderRadius: 5 }} />
          </div>
        </Hidden>
        <div style={{ display: 'block' }}>
          <Grid container spacing={{ xs: 1, md: 5 }} >
            {
              images2.map((image, index) => (
                <Fragment key={index}>
                  <Grid item xs={6} md={3} style={{}}>
                    <Button
                      onClick={() => handleRouter(image.code)}
                      className={classes.button}>
                      <img className={classes.image} src={image.url} alt="" />
                    </Button>
                  </Grid>
                </Fragment>
              ))
            }
          </Grid>
        </div>
      </Stack>
    </Content>
  )
}

export default Home