import { ButtonBase, styled } from "@mui/material";

export const Content = styled('div')((props) => ({
    padding: '12px 0px',
    display: 'block',
    backgroundColor: '#edeef2',
    width: '1170px',
    margin: '0px auto',
    [props.theme.breakpoints.down('lg')]: {
        width: 'calc(100%)',
    },
    [props.theme.breakpoints.down('md')]: {
        width: 'calc(100%)',
    },
    [props.theme.breakpoints.down('sm')]: {
        width: 'calc(100%)',
    },
    [props.theme.breakpoints.down('xs')]: {
        width: 'calc(100%)',
    },
}))

export const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
}));

export const SquareImage = styled('div')((props) => ({
    height: 400,
    width: 400,
    [props.theme.breakpoints.down('lg')]: {
        height: 400,
    },
    [props.theme.breakpoints.down('md')]: {
        width: 'calc(100% - 80px)',
        margin: '0px auto'
    },
    [props.theme.breakpoints.down('sm')]: {
        width: 'calc(100%- 80px)',
    },
    [props.theme.breakpoints.down('xs')]: {
        width: 'calc(100% - 80px)',
    },
}))

export const ContentEditPage = styled('div')((props) => ({
    display: 'block',
    width: '1500px',
    margin: '32px auto',
    paddingTop: '12px',
    [props.theme.breakpoints.down('lg')]: {
        width: 'calc(100% - 128px)',
        margin: 0,
        paddingTop: 0
    },
    [props.theme.breakpoints.down('md')]: {
        width: 'calc(100% - 80px)',
        margin: 0,
        paddingTop: 0
    },
    [props.theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 32px)',
        margin: 0,
        paddingTop: 0
    },
    [props.theme.breakpoints.down('xs')]: {
        width: 'calc(100% - 24px)',
        margin: 0,
        paddingTop: 0
    },
}))

export const ImageContent = styled('div')((props) => ({
    display: 'block',
    height: '200px',
    backgroundColor: 'blue',
    [props.theme.breakpoints.down('md')]: {
        height: '200px'
    }
}))

export const BannerBlock = styled('a')((props) => ({
    display: 'block'
}))

export const DoListImg = styled('img')(() => ({
    height:'100%',
    width: '100%',
    borderRadius: '5px 59px'
})) 

export const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: '100%',
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.15,
        },
        '& .MuiImageMarked-root': {
            opacity: 0,
        },
        '& .MuiTypography-root': {
            border: '4px solid black',
        },
    },
}));

export const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center 40%',
});

export const Image = styled('span')(({ theme }) => ({
    color: theme.palette.common.white,
}));

export const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create('opacity'),
}));